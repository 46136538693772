/* eslint-disable */
import Swiper, {
    Navigation,
    Pagination,
    Scrollbar,
    Autoplay,
    FreeMode,
    EffectFade,
} from 'swiper';

if ($("#home-hero-banner").length) {
    const homeBannerSwiper = new Swiper("#home-hero-banner", {
        modules: [Navigation, Pagination, Scrollbar, Autoplay],
        autoplay: {
            delay: 5000,
        },
        slidesPerView: 1,
        setWrapperSize: true,
        loop: true,
        speed: 1000,
        keyboard: {
            enabled: true,
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
    });
}

if ($("#home-hero-carousel").length) {
    window.homeBannerCarousel = new Swiper("#home-hero-carousel", {
        modules: [Pagination, Autoplay, EffectFade],
        autoplay: {
            delay: 6000,
        },
        effect: 'fade',
        slidesPerView: 1,
        setWrapperSize: true,
        loop: true,
        speed: 750,
        keyboard: {
            enabled: true,
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        on: {
            slideChangeTransitionStart: function () {
                const currentSlide = document.getElementById('current-slide');
                const prevSlide = document.getElementById('prev-slide');
                const nextSlide = document.getElementById('next-slide');
                const video = document.querySelector('.swiper-slide-active video');

                const currentIndex = parseInt(currentSlide.textContent, 10);
                const newIndex = this.realIndex + 1; // 1-based index for the active slide

                // Update the next slide content
                prevSlide.textContent = `0${newIndex}`;
                nextSlide.textContent = `0${newIndex}`;

                if (newIndex > currentIndex) {
                    // Slide up
                    prevSlide.style.transform = 'translateY(-100%)';
                    currentSlide.style.transform = 'translateY(-100%)';
                    nextSlide.style.transform = 'translateY(0)';
                } else {
                    // Slide down
                    prevSlide.style.transform = 'translateY(0)';
                    currentSlide.style.transform = 'translateY(100%)';
                    nextSlide.style.transform = 'translateY(100%)';
                }

                if (video) {
                    video.currentTime = 0;
                    video.play();
                }

                setTimeout( function (){
                    // Temporarily disable transitions
                    prevSlide.style.transition = 'none';
                    currentSlide.style.transition = 'none';
                    nextSlide.style.transition = 'none';

                    currentSlide.textContent = `0${newIndex}`;

                    prevSlide.style.transform = 'translateY(-100%)';
                    currentSlide.style.transform = 'translateY(0)';
                    nextSlide.style.transform = 'translateY(100%)';
                }, 500)

                setTimeout( function (){
                    prevSlide.style.transition = '';
                    currentSlide.style.transition = '';
                    nextSlide.style.transition = '';
                }, 600);
            },
        },
    });
    $(document.body).on('click', '.home-hero-carousel .swipe-next', function () {
        let targetSlide = Number($(this).attr('data-slideto'));
        window.homeBannerCarousel.slideTo(targetSlide, 500, true);
    });
}

if ($("#usp-carousel").length) {
    const uspSwiper = new Swiper("#usp-carousel", {
        modules: [Pagination],
        autoplay: false,
        simulateTouch: false,
        slidesPerView: 1,
        setWrapperSize: true,
        loop: true,
        speed: 1000,
        keyboard: {
            enabled: true,
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        breakpoints: {
            576: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
            },
            1024: {
                slidesPerView: 4,
            },
            1160: {
                slidesPerView: 5,
            },
        }
    });
}


let defaultCarouselSettings = {
    modules: [Navigation, Scrollbar, FreeMode],
    spaceBetween: 20,
    slidesPerView: 'auto',
    setWrapperSize: true,
    speed: 500,
    freeMode: true,
    keyboard: {
        enabled: true,
    },
    scrollbar: {
        el: ".swiper-scrollbar",
        hide: false,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      // when window width is >= 320px
        768: {
            spaceBetween: 32
        },
    },
}

if ($("#favourites-carousel").length) {
    const favouritesSwiper = new Swiper("#favourites-carousel", defaultCarouselSettings);
}

if ($("#other-collections-carousel").length) {
    const favouritesSwiper = new Swiper("#other-collections-carousel", defaultCarouselSettings);
}

if ($("#ancillary-carousel").length) {
    const ancillarySwiper = new Swiper("#ancillary-carousel", defaultCarouselSettings);
}

if ($("#my-mandarin-carousel").length) {
    const myMandarinCarousel = new Swiper("#my-mandarin-carousel", defaultCarouselSettings);
}

if ($("#related-carousel").length) {
    const relatedSwiper = new Swiper("#related-carousel", defaultCarouselSettings);
}

if ($("#showroom-carousel").length) {
    const showroomSwiper = new Swiper("#showroom-carousel", defaultCarouselSettings);
}

if ($("#colour-feature-carousel").length) {
    const colourFeatureSwiper = new Swiper("#colour-feature-carousel", defaultCarouselSettings);
}


if ($("#inspiration-featured-posts").length) {
    const inspirationFeaturedSwiper = new Swiper("#inspiration-featured-posts", {
        modules: [Navigation, Autoplay],
        autoplay: false,
        slidesPerView: 1,
        setWrapperSize: true,
        autoHeight: true,
        loop: true,
        speed: 750,
        keyboard: {
            enabled: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
}


if ($("#single-post-swiper").length) {
    const singlePostSwiper = new Swiper("#single-post-swiper", {
        modules: [Navigation, Autoplay],
        autoplay: false,
        slidesPerView: 1,
        setWrapperSize: true,
        autoHeight: true,
        loop: true,
        speed: 750,
        keyboard: {
            enabled: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
}

if ($(".inspiration-category-carousel").length) {
    $(".inspiration-category-carousel").each(function (index) {
        const inspirationCategoryCarousel = new Swiper(".icc_" + index, defaultCarouselSettings);
    });
}

if ($("#related-posts-carousel").length) {
    const relatedPostsSwiper = new Swiper("#related-posts-carousel", {
        modules: [Navigation, Scrollbar, FreeMode],
        spaceBetween: 20,
        slidesPerView: 'auto',
        setWrapperSize: true,
        speed: 500,
        freeMode: true,
        keyboard: {
            enabled: true,
        },
        scrollbar: {
            el: ".swiper-scrollbar",
            hide: false,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            // when window width is >=1024px
            1024: {
                spaceBetween: 32,
                slidesPerView: 3,
            },
        }
    });
}



// listen for when quickview wrapper is inserted
$(document.body).on('quickview_wrapper_inserted', function () {

    // setup variable to store swiper instance
    let quicklookCarouselSwiper = false;

    // activate swiper on modal show
    $(document).on('show.ud.modal', '#modal-quickview', function () {
        quicklookCarouselSwiper = new Swiper("#quicklook-carousel", {
            modules: [Navigation, Autoplay],
            autoplay: false,
            slidesPerView: 1,
            setWrapperSize: true,
            loop: true,
            speed: 750,
            keyboard: {
                enabled: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    });

    // destroy swiper on modal hide
    $(document).on('hide.ud.modal', '#modal-quickview', function () {
        if (quicklookCarouselSwiper) {
            quicklookCarouselSwiper.destroy(true, true);
        }
    });

});

