/**
 * De-bouncer script
 */
window.debounce = (func, delay) => {
    let inDebounce
    return function () {
        const context = this
        const args = arguments
        clearTimeout(inDebounce)
        inDebounce = setTimeout(() => func.apply(context, args), delay)
    };
};


/**
 * Load external js script
 */
window.loadScript = (url, callback) => {

    // if script already loaded then dont proceed
    if ($('script[src$=\'' + url + '\']').length) {
        return;
    }

    // adding the script element to the head as suggested before
    let head = document.getElementsByTagName('head')[0];
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;

    // then bind the event to the callback function
    // there are several events for cross browser compatibility
    script.onreadystatechange = callback;
    script.onload = callback;

    // fire the loading
    head.appendChild(script);

};


/**
 * Calculate piece quantity from m2
 */
window.calc_piece_qty_from_m2 = (m2_qty, product_sqm) => {
    return (product_sqm > 0 ? Math.ceil((m2_qty / product_sqm)) : 0);
};


/**
 * Calculate piece quantity from m2
 */
window.calc_m2_qty_from_piece = (piece_qty, product_sqm) => {
    return (product_sqm * piece_qty).toFixed(2);
};


/**
 * Calculate price with tax
 */
window.calc_price = (unit_price, qty, tax_rate) => {

  // multiply unit cost by quantity required and add tax
    let total = (unit_price * qty) * (1 + ('.' + tax_rate));

  // if is not nan then return
    if (!isNaN(total)) {
        return total.toFixed(2);
    }

};


/**
 * Return ajax url
 */
window.get_ajax_url = () => {
    return '/wp/wp-admin/admin-ajax.php';
};


/**
 * Load fragments function
 */
window.load_fragments = (requested_fragments = false, on_element_updated = false, post_args = {}) => {
    $.ajax({
        url: window.get_ajax_url() + '?trigger=get_fragments',
        type: 'POST',
        dataType: 'JSON',
        data: $.extend({
            action: 'get_fragments',
            requested_fragments: requested_fragments,
        }, post_args),
    success: function (fragments) {

        // loop through fragments and update each element
        Object.keys(fragments).forEach(function (key) {

            // get html
            let html = fragments[key];

            // update html and trigger updated event
            $(key).html(html).trigger('element_updated', on_element_updated);
        });
    },
    });
};


/**
 * Initialize fragments on hover
 */
window.initialize_fragments_on_hover = () => {

    // setup variable to store the states
    let loadedOnHoverFragments = {};

    // look for `data-load-fragment-on-hover` elements
    document.querySelectorAll('[data-load-fragment-on-hover]').forEach((element, i) => {

        // set marker to indicate this item has not been loaded yet
        loadedOnHoverFragments[i] = false;

        // get current fragment selector
        let fragment_selector = $(element).attr('data-load-fragment-on-hover');

        // define the mouseenter handler
        const loadFragmentOnHover = () => {

            // don't proceed if marker has already been set true to load
            if (loadedOnHoverFragments[i]) {
                return;
            }

            // add fragment loading class
            $(element).addClass('fragment-loading');

            // set marker to indicate we're attempting to load it
            loadedOnHoverFragments[i] = true;

            // load fragment
            window.load_fragments(fragment_selector, () => {
                // remove loading class
                $(element).removeClass('fragment-loading');

                // remove attribute now its loaded
                $(element).removeAttr('data-load-fragment-on-hover');

                // remove the event listener after loading is done
                element.removeEventListener('mouseenter', loadFragmentOnHover);
            });
        };

        // listen for mouse enter on this element
        element.addEventListener('mouseenter', loadFragmentOnHover);
    });
};


