'use strict';

/**
 * Library for the mandarin stone wish list
 */
class wishList {


    /**
     * Triggered on page load
     */
    constructor()
    {

        // detect if wish list page
        this.is_wishlist_page = $(document.body).hasClass('wish-list');

        // load event listeners
        this.listeners();

    }


    /**
     * Wish list event listeners
     */
    listeners()
    {

        // set that let to enable child functions to scope outside
        let that = this;

        //  listen for click on add to wish list button on product listing
        $(document.body).on('click', '.js-wishlist, .product-add-to-wishlist', function (e) {
            that.add_to_wishlist_click(this);
            e.stopPropagation();
        });

        //  listen for click `add to wish list` button on single product
        $('.single-product').on('click', '.product-add-to-wishlist', function (e) {
            e.preventDefault();
            that.add_to_wishlist_click(this);
        });

        // listen for for filter product data items event
        $(document.body).on('filter_product_data_items', function (e, product_results ) {
            that.update_products_wishlist_button_on_product_listing_data(product_results);
        });

        // listen for wishlist updated event
        $(document.body).on('wishlist_updated', function (e, data) {
            that.wishlist_updated(data);
        });

        //  share wish list add recipient
        $(document.body).on('click', '#slideout-share-wishlist #modal-add-recipient', function () {
            that.share_wishlist_add_recipient(this);
        });

        //  remove recipient from users wish list recipients
        $(document.body).on('click', '#slideout-share-wishlist .recipients .remove-recipient span', function (e) {
            that.share_wishlist_remove_recipient(e);
        });

        //  send shared wish list to delegated recipients and email addresses
        $(document.body).on('click', '#slideout-share-wishlist button.submit', function (e) {
            that.share_wishlist_send(e);
        });

        // listen for event to sync wish list to browsers local storage
        $(document.body).on('sync_users_wish_list_to_browser', function () {
            that.sync_users_wish_list_to_browser();
        });

        // update wishlist buttons in product card when the page is fully loaded
        $(document).ready(function () {
            that.update_products_wishlist_button_on_page_load();
        });
    }


    /**
     * Update wishlist buttons when the page is fully loaded
     */
    update_products_wishlist_button_on_page_load()
    {
        document.querySelectorAll('.product-card').forEach((element) => {
            this.update_products_wishlist_button($(element));
        });
    }


    /**
     * Updates the wish list button's class on product listing items before they get injected
     */
    update_products_wishlist_button_on_product_listing_data(product_result_item_elements)
    {
        if (!product_result_item_elements) {
            return;
        }
        product_result_item_elements.map((product_item) => {
            this.update_products_wishlist_button(product_item);
        });
    }


    /**
     * Update products wishlist button for a given product card
     */
    update_products_wishlist_button(product_item)
    {

        // find the wishlist button within the jquery object
        let wishlist_button_elem = product_item.find('.js-wishlist');

        // get the product id
        const product_id = wishlist_button_elem.attr('data-prodid');

        // update class and title based on whether the product is in the wishlist
        if (this.is_in_wish_list(product_id)) {
            wishlist_button_elem.addClass('wishlist-saved').prop('title', 'Remove from Wishlist');
        } else {
            wishlist_button_elem.removeClass('wishlist-saved').prop('title', 'Add to Wishlist');
        }
    }


    /**
     * Detects whether add/remove wish list action is necessary based on button elements class
     */
    add_to_wishlist_click(button)
    {

        // trigger ud-event clicks wish list page
        if ($(document.body).hasClass('wish-list')) {
            $(document.body).trigger('ud-event', 'clicks_wish_list_page');
        }

        if ( $(button).hasClass('wishlist-saved') ) {
            this.remove_from_wishlist(button);
        } else {
            this.add_to_wishlist(button);
        }
    }


    /**
     * Updates header wish list items count
     */
    wishlist_updated(data)
    {
        // update local storage with wish list ids
        localStorage.setItem('wish_list_ids', JSON.stringify(data.data.wish_list_ids));
    }


    /**
     * Detect if product id is in customers wish list
     */
    is_in_wish_list(product_id)
    {
        if (product_id) {
            product_id = parseInt(product_id);
            const wish_list_ids = JSON.parse(localStorage.getItem('wish_list_ids')) || [];
            return Array.isArray(wish_list_ids) && wish_list_ids.includes(product_id);
        }
    }


    /**
     * Adds item to wish list from passed button element
     */
    add_to_wishlist(button)
    {

        // get product id from button
        let product_id = $(button).data('prodid');

        // perform add to wish list ajax
        $.ajax({
            url:window.get_ajax_url() + '?trigger=add-to-wishlist',
            data: {
                a: 'add',
                product_id: product_id,
                action: 'mswishlist',
            },
            type: 'POST',
            dataType: 'json',
            success: function (response) {

                // detect success
                if (response && response.status == 'ok') {
                    // add `saved` class to indicate this item has been added to wish list
                    $(button).addClass('wishlist-saved').prop('title', 'Remove from Wishlist');
                    if ($(button).find('span').length) {
                        $(button).find('span').text('Remove');
                    }

                    // if has a redirect attribute follow it
                    if ($(button)[0].hasAttribute('data-redir')) {
                        window.location.href = $(button).attr('data-redir');
                    }

                    // trigger wish list info updated event
                    $(document.body).trigger('wishlist_updated', [response]);
                }

                // detect error
                if (response && response.status == 'error') {
                    // if user not logged in
                    if (response.data.action == 'login' && response.data.url) {
                        // set cookie so once logged in item will be added to wish list
                        window.Cookies.set('added_item_to_wishlist_before_login', product_id);

                        // redirect user to the login page
                        window.location.href = response.data.url;
                    }
                }

            },
        });

        // track event
        $(document.body).trigger('ud-event', 'add_to_wishlist', button);

    }


    /**
     * Removes item from wish list from passed button element
     */
    remove_from_wishlist(button)
    {

        // set that let to enable child functions to scope outside
        let that = this;

        // get product id from button
        let product_id = $(button).data('prodid');

        $.ajax({
            url: window.get_ajax_url() + '?trigger=remove-from-wishlist',
            data: {
                a: 'remove',
                product_id: product_id,
                action: 'mswishlist',
            },
            type: 'POST',
            dataType: 'json',
            success: function (response) {

                if (response && response.status == 'ok') {
                    // depending on if wish list page do a different remove event
                    if ( that.is_wishlist_page ) {
                        // fade out element effect and then remove element
                        $(button).closest('.product-col').fadeOut(600, function () {
                            $(this).remove();
                        });
                    } else {
                        // remove `saved` class to indicate this item has been added to wish list
                        $(button).removeClass('wishlist-saved').prop('title', 'Add to Wishlist');
                        if ($(button).find('span').length) {
                            $(button).find('span').text('Add to Wishlist');
                        }
                    }

                    // trigger wish list info updated event
                    $(document.body).trigger('wishlist_updated', [response]);
                }

            },
        });

    }


    /**
     * Add recipient to users wish list recipients
     */
    share_wishlist_add_recipient()
    {

        // don't allow more than 6 recipients
        if ( $('.recipients .recipient').length >= 6) {
            return;
        }

        let new_recipient = $('.recipients .recipient.original').clone();
        $('.error-notice', new_recipient).removeClass('error-notice');
        $('.error-notice-reason', new_recipient).remove();
        let index = $('.recipients .recipient').length;
        new_recipient.attr('id', 'share_wishlist_recipient_' + index).removeClass('original').find('input').val('');
        $(new_recipient.find('input')).each(function () {
            $(this).attr('id', $(this).attr('id').replace('_0', '_' + index));
        });
        $('.recipients').append(new_recipient);

    }


    /**
     * Remove recipient from users wish list recipients
     */
    share_wishlist_remove_recipient(e)
    {
        let button = $(e.currentTarget);
        let recipient_container = button.closest('.recipient');
        recipient_container.fadeOut('slow', function () {
            $(this).remove();
        });
    }


    /**
     * Build share ajax request
     */
    build_share_ajax_request(e, data)
    {
        return {
            a: 'share',
            action: 'mswishlist',
            d: data,
        };
    }


    /**
     * Send shared wish list to delegated recipients
     */
    share_wishlist_send(e)
    {

        // serialize form data
        let data = $('#slideout-share-wishlist form').serialize();

        // set that let to enable child functions to scope outside
        let that = this;

        // execute ajax request
        this.xhr = $.ajax({
            url: window.get_ajax_url() + '?trigger=share_wishlist_send',
            type: 'POST',
            data: that.build_share_ajax_request(e, data),
            success(data) {

                // clear old error notices
                $('.error-notice').removeClass('error-notice');
                $('.error-notice-reason').remove();

                // parse json response
                let response = JSON.parse(data);

                // handle errors
                if ( response.status == 'error') {
                    // if user not logged in
                    if ( response.data.action == 'login' && response.data.url ) {
                        // redirect user to the login page
                        window.location.href = response.data.url;
                    }

                    // get fields errors
                    let obj = response.data.errors;

                    // add error notices to each invalid field
                    Object.keys(obj).forEach(function (key) {

                        // get key and values
                        let e_key = key;
                        let e_value = obj[key];

                        // find element
                        let target_elem = $('#' + e_key).closest('.form-row');

                        // add error class to element
                        target_elem.addClass('error-notice');

                        // get error message
                        let error_message_reason = e_value;

                        // insert error message
                        if (target_elem.find('abbr').length) {
                            $('<span class=\'error-notice-reason\'>' + error_message_reason + '</span>').insertBefore(target_elem.find('abbr'));
                        }

                    });
                }

                // success - close and remove slide out
                else {
                    window.slideout('hide', '#slideout-share-wishlist', {'remove_element_on_close':1});
                }

            },

        });
    }


    /**
     * Sync users wish list to clients browser
     */
    sync_users_wish_list_to_browser()
    {
        $.ajax({
            url: window.get_ajax_url() + '?trigger=sync-users-wish-list',
            data: {
                a: 'get',
                action: 'mswishlist',
            },
            type: 'POST',
            dataType: 'json',
            success: (response) => {
                if (response && response.status == 'ok') {
                    this.wishlist_updated(response);
                }
            },
        });
    }

}

export default wishList
