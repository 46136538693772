'use strict';

/**
 * Library for loading live chat
 */
class msLiveChat {

    /**
     * Triggered on page load
     */
    constructor()
    {
        this.show_lc_trigger_hold = false;

        this.initialize();
    }

    initialize()
    {
        this.chat_js_listeners();
    }

    get_chat_js()
    {
        return `
            window.__lc = window.__lc || {};
            window.__lc.license = 9031595;
            window.__lc.asyncInit = true;
            window.__lc.integration_name = "manual_channels";
            window.__lc.product_name = "livechat";
            ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice));
        `;
    }

    chat_js_listeners()
    {

        // On Page load - initialize the livechat library, but don't load the widget
        window.addEventListener('load', () => {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.innerHTML = this.get_chat_js();
            document.head.appendChild(script);

            // Listener for live chat link opener
            $(document).on('click', 'a[href$="#live-chat"], #live-chat', function (e) {
                // console.log('clicked live chat');
                e.preventDefault;
                const event = new CustomEvent('clicked_chat_start');
                window.dispatchEvent(event);
            });

            // If url has #livechat then open window
            if (window.location.hash == '#livechat') {
                // console.log('URL has livechat');
                const event = new CustomEvent('clicked_chat_start');
                window.dispatchEvent(event);
            }

            // Check if the chat was previously started
            let chatStarted = localStorage.getItem('livechat_chat_visible');
            if (chatStarted === 'true') {
                // console.log('Was open before');
                this.initialise_chat_widget();
            }

            // Listen for visibility changes in the LiveChat widget
            LiveChatWidget.on('visibility_changed', function(data) {
                // console.log('visibility changed to ' + data.visibility);
                switch (data.visibility) {
                    case 'minimized':
                        LiveChatWidget.call('hide');
                        $(document.body).trigger('ud-event', 'live_chat_closed');
                        break
                    case 'hidden':
                        localStorage.setItem('livechat_chat_visible', 'false');
                        $('.lc-trigger').fadeIn();
                        $('.lc-trigger-hold').removeClass('show-eye-catcher');
                        window.Cookies.set('eye-catcher-hidden', 1);
                        $(document.body).trigger('ud-event', 'live_chat_closed');
                        break;
                    case 'maximized':
                        localStorage.setItem('livechat_chat_visible', 'true');
                        $('.lc-trigger').fadeOut();
                        break;
                }
            });

            // Listen for visibility changes in the LiveChat widget
            LiveChatWidget.on('customer_status_changed', function(data) {
                // console.log('visibility changed to ' + data.visibility);
                switch (data.visibility) {
                    case 'chatting':
                        $(document.body).trigger('ud-event', 'live_chat_started');
                        break;
                }
            });

            // If eyecatcher not hidden - pop it up
            if (!window.Cookies.get('eye-catcher-hidden')) {
                console.log('show eye catcher');
                setTimeout(function() {
                    $('.lc-trigger-hold').addClass('show-eye-catcher');
                }, 5000)
            }

        });

        // Button to trigger initialising widget
        $(document.body).on('click', '.lc-trigger', function() {
            const event = new CustomEvent('clicked_chat_start');
            window.dispatchEvent(event);
        });

        $(document.body).on('click', '.lc-eye-catcher-close', function() {
            $('.lc-trigger-hold').removeClass('show-eye-catcher');
            window.Cookies.set('eye-catcher-hidden', 1);
            $(document.body).trigger('ud-event', 'live_chat_closed');
        });


        // Event which triggers live chat init and maximise
        window.addEventListener('clicked_chat_start', () => {
            this.initialise_chat_widget();
        });

        // ???
        window.addEventListener('scroll', window.debounce(() => {
            if (window.scrollY >= 10) {
                this.maybe_show_lc_trigger_hold();
            }
        }, 500));

        // ???
        window.addEventListener('mousemove', window.debounce( () => {
            this.maybe_show_lc_trigger_hold();
        }, 500));
    }

    maybe_show_lc_trigger_hold()
    {
        if (this.show_lc_trigger_hold) {
            return;
        }
        $('.lc-trigger-hold').addClass('show');
        this.show_lc_trigger_hold = true;
    }

    initialise_chat_widget() {
        LiveChatWidget.init();
        setTimeout(function () {
            LiveChatWidget.call('maximize');
            $(document.body).trigger('ud-event', 'live_chat_opened');
        }, 1000);
    }
}

export default msLiveChat
