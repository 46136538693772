'use strict';

/**
 * Library for the mandarin stone single product
 */
class Basket {


    /**
     * Triggered on page load
     */
    constructor()
    {

        // variable to store scroll position
        this.scroll_y = 0;

        // event listeners
        this.listeners();

    }


    /**
     * Event listeners
     */
    listeners()
    {

        // copy of self when out of scope
        let that = this;

        // listen for basket scroll
        $(document).on('show.ud.slideout refresh.ud.slideout', '#slideout-basket', function (e) {
            let item_wrapper = $(e.currentTarget).find('.basket-items-wrapper');
            item_wrapper.on('scroll', function () {
                that.track_basket_scroll_y(this);
            });
        });

        // listen for user initiated show basket event
        $(document).on('show.ud.slideout', '#slideout-basket', function (e, data) {
            if ('mini-basket' == $(e.delegateTarget.activeElement).attr('id')) {

                console.log('#slideout-basket');
                console.log(data);

                $(document.body).trigger('ud-event', ['open_basket', data]);
            }

            $('.big-menu-close').trigger('close-big-menu');
        });

        // listen for remove from cart click
        $(document).on('click', '*[data-remove-from-cart]', function (e) {
            that.remove_from_cart($(this).attr('data-remove-from-cart'), e);
        });

        // listen for cart slider refresh completing when cart action is made
        $(document.body).on('slideout-refreshed', function (e, args) {
            if (typeof args.slideout_id !== 'undefined' && args.slideout_id === 'basket') {

                // remove doing cart action indicator on body
                $(document.body).removeClass('doing-cart-action');

                // scroll to last position after update
                if (that.scroll_y) {
                    $('.basket-items-wrapper').scrollTop(that.scroll_y);
                }

            }
        });

        // listen for increase/decrease
        $(document.body).on('click', '#slideout-basket .qty-button', function (e) {
            that.increase_decrease_qty_handler(e);
        });

        // block anything other than an integer on the piece input field
        $(document).on('keypress', '#slideout-basket .qty-input.piece', function (e) {
            return e.charCode >= 48 && e.charCode <= 57;
        });

        // quantity input handler
        $(document).on('keyup', '#slideout-basket .qty-input', function (e) {
            that.quantity_input_handler(e);
        });

        // quantity input handler
        $(document).on('qty_update', '#slideout-basket', window.debounce(function () {
            that.quantity_update_handler();
        }, 1000));

        // listen for calculate delivery button click
        $(document).on('click', '*[data-calculate-delivery-estimate]', function () {
            that.calculate_delivery_estimate_handler();
        });

        // when window has loaded check whether to show basket
        this.maybe_show_cart_on_load();
    }


    /**
     * Track basket scroll position
     */
    track_basket_scroll_y(item_wrapper)
    {
        this.scroll_y = $(item_wrapper).scrollTop();
        console.log(this.scroll_y);
    }


    /**
     * Remove item from basket
     */
    remove_from_cart(cart_item_key, e)
    {

        // add class to body to indicate cart action is on going
        $(document.body).addClass('doing-cart-action')

        // copy of self when out of scope
        let that = this;

        // do ajax request
        this.xhr = $.ajax({
            url: window.get_ajax_url() + '?action=remove_from_cart',
            data: {
                'action': 'remove_from_cart',
                'cart_item_key': cart_item_key,
            },
            type: 'POST',
            beforeSend: function () {
                if (that.xhr != null) {
                    that.xhr.abort();
                }
            },
            success: function (data) {

                // trigger basket refresh event
                $('#slideout-basket').trigger('refresh', {'basket_action': 'remove'});

                // trigger global fragment refresh
                $(document.body).trigger('refresh_global_fragments');

                // trigger event
                $(document.body).trigger('ud-event', ['remove_from_cart', data, e]);
            },

        });
    }


    /**
     * Update qty of product
     */
    update_qty(items)
    {

        // add class to body to indicate cart action is on going
        $(document.body).addClass('doing-cart-action')

        // copy of self when out of scope
        let that = this;

        // do ajax request
        this.xhr = $.ajax({
            url: window.get_ajax_url() + '?action=update_cart_qty',
            data: {
                'action': 'update_cart_qty',
                'items': items,
            },
            type: 'POST',
            beforeSend: function () {
                if (that.xhr != null) {
                    that.xhr.abort();
                }
            },
            success: function () {

                // trigger basket refresh event
                $('#slideout-basket').trigger('refresh', {'basket_action': 'update_qty'});

                // trigger global fragment refresh
                $(document.body).trigger('refresh_global_fragments');

            },

        });

    }


    /**
     * Increase/decrease quantity in cart
     */
    increase_decrease_qty_handler(e)
    {
        let $button = $(e.currentTarget);
        let oldValue = ($button.parent().find('.qty-input').val() ? $button.parent().find('.qty-input').val() : 0);
        let newVal = ''
        $button.parent().find('.qty-btn[data-action="decrease"]').removeClass('inactive');
        if ($button.data('action') == 'increase') {
            newVal = parseFloat(oldValue) + 1;
        } else {
            // Don't allow decrementing below 1 on product page
            if ($('body').hasClass('single-product')) {
                if (oldValue > 1) {
                    newVal = parseFloat(oldValue) - 1;
                } else {
                    newVal = 1;
                    $button.addClass('inactive');
                }
            } else {
                if (oldValue > 0) {
                    newVal = parseFloat(oldValue) - 1;
                } else {
                    newVal = 1;
                    $button.addClass('inactive');
                }
            }
        }
        $button.parent().find('.qty-input').val(newVal).trigger('keyup');
        e.preventDefault();
    }


    /**
     * Handles quantity input event
     */
    quantity_input_handler(e)
    {

        // changed element
        let qty_elem = $(e.currentTarget);

        // parent basket item element
        let basket_item_elem = qty_elem.closest('.basket-item');

        // variables
        let vars = JSON.parse(basket_item_elem.attr('data-product-vars'));

        // convert quantity for m2 products only
        if (vars['is_m2_product']) {
            // last changed quantity type
            let last_changed_qty_type = ('m2' === qty_elem.attr('data-qty-type') ? 'm2' : 'piece');

            // if the last change qty type was m2
            if (last_changed_qty_type === 'm2') {
                // update piece qty
                basket_item_elem.find('.qty-input:eq(0)').val(window.calc_piece_qty_from_m2(qty_elem.val(), vars['product_sqm']));
            } else {
                // update m2 qty
                basket_item_elem.find('.qty-input:eq(1)').val(window.calc_m2_qty_from_piece(qty_elem.val(), vars['product_sqm']));
            }
        }

        // update basket line price
        basket_item_elem.find('.basket-item-price bdi').text('£' + window.calc_price(vars['regular_price_exvat'], basket_item_elem.find('.qty-input:eq(0)').val(), vars['product_tax_rate']));

        // add class to indicate which qty to post back to woo cart for update
        basket_item_elem.find('.qty-input:eq(0)').addClass('modified');

        // trigger event to perform qty update
        $('#slideout-basket').trigger('qty_update');

    }


    /**
     * Basket quantity update handler
     */
    quantity_update_handler()
    {
        // copy of self when out of scope
        let that = this;

        // setup variable to store items to update
        let items = {};

        // build a list of items to update
        $('#slideout-basket .basket-item').each(function () {
            let qty_elem = $(this).find('.qty-input:eq(0)');
            if (qty_elem.hasClass('modified')) {

                // variables
                let vars = JSON.parse($(this).attr('data-product-vars'));

                // add to update items
                items[vars['cart_item_key']] = qty_elem.val();

            }
        });

        // perform update
        that.update_qty(items);

    }


    /**
     * Calculate delivery estimate handler
     */
    calculate_delivery_estimate_handler()
    {
        // add class to body to indicate cart action is on going
        $(document.body).addClass('doing-cart-action');

        // make ajax request
        $.ajax({
            url: window.get_ajax_url() + '?trigger=get_cart_shipping_calculation',
            dataType: 'json',
            type: 'POST',
            data: {
                action: 'get_cart_shipping_calculation',
                delivery_estimate_postcode: $('.basket-delivery-estimate #delivery-postcode').val(),
            },
            success: function (result) {
                $('.basket-delivery-estimate .errors').text('');
                if (typeof result.error !== 'undefined') {
                    $('.basket-delivery-estimate .errors').text(result.error);
                } else {
                    $('.basket-delivery-estimate .estimated-cost-wrapper').addClass('d-flex').removeClass('d-none');
                    $('.basket-delivery-estimate .estimated-price').text(result.price);
                }

                // remove doing cart action indicator on body
                $(document.body).removeClass('doing-cart-action');
            },
        });

        // track event
        $(document.body).trigger('ud-event', 'delivery_calculation_estimate');

    }


    /**
     * When window has loaded check whether to show basket
     */
    maybe_show_cart_on_load()
    {
        if ('cart' == window.location.hash.substr(1)) {
            window.slideout('show', 'basket');
        }
    }

}

export default Basket
