/**
 * TrustPilot widget
 */
class msTrustpilotWidget {
    /**
     * Initialisation function
     * @param {Element} parent_elem - The parent element to be observed.
     */
    constructor(parent_elem)
    {
        // Store parent element
        this.parent_elem = parent_elem;

        // Initialize the Intersection Observer
        this.initObserver();
    }

    /**
     * Initialize the Intersection Observer
     */
    initObserver()
    {
        // Callback function to execute when the observed element changes visibility
        const handleIntersection = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // The element is in view, perform your action here
                    this.onElementInView(entry.target);

                    // Optionally, unobserve the element if you only need to trigger once
                    observer.unobserve(entry.target);
                }
            });
        };

        // Create the Intersection Observer with the callback and options
        this.observer = new IntersectionObserver(handleIntersection, {
            root: null, // Use the viewport as the root
            rootMargin: '0px', // Margin around the root
            threshold: 1.0, // Trigger when 100% of the element is visible
        });

        // Start observing the parent element
        this.observeElement(this.parent_elem);
    }

    /**
     * Observe the specified element
     * @param {Element} element - The element to be observed.
     */
    observeElement(element)
    {
        if (element) {
            this.observer.observe(element);
        }
    }

    /**
     * Load widget library via `window.loadScript` as will only ever load in one library avoiding duplicates
     */
    onElementInView()
    {
        window.loadScript('https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js');
    }
}
export default msTrustpilotWidget;
