'use strict';

/**
 * slide out library
 */
class udslideouts {

    /**
     * Initialisation function
     */
    constructor()
    {

        // listen for other events
        this.event_listeners();

    }


    /**
     * Listen for other events
     */
    event_listeners()
    {

        // instantiate a copy of self when out of scope
        let that = this;

        // open/close slide out listeners
        $(document).on('click', '[data-open-slideout]', function (e) {
            that.data_open_slideout_handler(e);
        });

        // close slide out listeners
        $(document).on('click', '[data-close-slideout]', function (e) {

            // determine slide out target
            let slideout_target = $(e.currentTarget).attr('data-close-slideout') ? $(e.currentTarget).attr('data-close-slideout')[0] : $(e.currentTarget).closest('.slideout-wrapper')[0];

            // hide slide out
            that.slideout('hide', slideout_target, that.get_element_args($(e.currentTarget), 'close'));

        });

        // listen for refresh event
        $(document).on('refresh', '.slideout-wrapper', function (e, args) {

            // slider element
            let slider_target = $(e.currentTarget).attr('data-slideout-target');

            // do slider refresh
            that.slideout('refresh', slider_target, args);

        });

        $(document).on('click', 'body.slideout-open .slideout-wrapper', function (e) {
            if (e.target === e.currentTarget) {
                that.slideout('hide', e.currentTarget, that.get_element_args($(e.currentTarget).find('*[data-close-slideout-args]'), 'close'));
            }
        });

        document.addEventListener('keydown', function (event) {
            if (event.key == 'Escape') {
                $('[data-close-slideout]').click();
            }
        });

    }


    /**
     * Process `data_open_slideout` click handler
     */
    data_open_slideout_handler(e)
    {

        // prevent event bubbling
        e.stopPropagation();

        // prevent default action
        e.preventDefault();

        // button element
        let button_elem = $(e.currentTarget);

        // obtain slide out target
        let target = button_elem.attr('data-open-slideout');

        // get any slide out args // ??? not sure if we need this
        let args = this.get_element_args(button_elem, 'open');

        // perform slide out show
        window.slideout('show', target, args);

    }


    /**
     * Gets any additional arguments attached to the element
     */
    get_element_args(elem, type = '')
    {
        let attr_name = 'data' + (type ? '-' + type : '') + '-slideout-args';
        return (elem && elem.length > 0 && elem[0].hasAttribute(attr_name)) ? JSON.parse(elem.attr(attr_name)) : {};
    }


    /**
     * Detect if a slider is open
     */
    is_open()
    {
        return $('.slideout-wrapper.visible').length
    }


    /**
     * slide out show/hide function
     */
    slideout(action, target, args = {})
    {

        // exit if target is null
        if (!target) {
            return;
        }

        // instantiate a copy of self when out of scope
        let that = this;

        // slide out id
        let slideout_id = (typeof target === 'string' || target instanceof String ? target : $(target).attr('id')).replace('#', '').replace('slideout-', '');

        // when slide out show is requested
        if (action == 'show') {

            // if slide out element already exists then open it
            if ($('#slideout-' + slideout_id).length) {

                // stops slide out re-opening before its removed
                if ($('body').hasClass('slideout-removing')) {
                    return;
                }

                // add visible class to slide out element
                $('#slideout-' + slideout_id).addClass('visible');

                // add `slideout-open` class to body and trigger event
                $('body').trigger('slideout-open', {'slideout_id' : slideout_id}).addClass('slideout-open');

                // trigger event to indicate that the slide out has loaded and is shown
                $('#slideout-' + slideout_id).trigger('show.ud.slideout', $.extend( {'slideout_id' : slideout_id}, args ));

                // update global variable that indicates a slide out has opened
                window.slideout_open = that.is_open();

            } else {

                // stops slide out re-opening before its removed
                if ($('body').hasClass('slideout-removing')) {
                    return;
                }

                // add class and trigger opening event on body
                $(document.body).trigger('slideout-opening', {'slideout_id' : slideout_id}).addClass('slideout-opening');

                // update global variable that indicates a slide out is opening
                window.slideout_opening = true;

                // generate slide out html
                let html = '<div class="slideout-wrapper ' + target + '" id="slideout-' + slideout_id + '" data-slideout-target="' + target + '" data-slideout-args=\'' + JSON.stringify(args) + '\'><div class=\'slideout-container\'></div></div>';

                // insert before the end of the body tag
                $(document.body).append(html);

                // add visible class to slide out element
                setTimeout(function () {
                    $('#slideout-' + slideout_id).addClass('visible loading');
                    $('body').addClass('slideout-open');
                }, 10);

                // make ajax call to retrieve slider html
                window.slideout_xhr = $.ajax({
                    url: window.get_ajax_url() + '?trigger=slide_out',
                    data: {
                        action: 'ms_slide_out',
                        target: target,
                        args: args,
                    },
                    type: 'POST',
                    dataType: 'json',
                    beforeSend: function () {
                        if (window.slideout_xhr != null) {
                            window.slideout_xhr.abort();
                        }
                    },
                    success: function (data) {

                        // populate slide out html
                        $('#slideout-' + slideout_id + ' .slideout-container').html(data.content);

                        // add class and trigger opening event on body
                        $(document.body).removeClass('slideout-opening');

                        // add `slideout-open` class to body
                        $('body').trigger('slideout-open', {'slideout_id' : slideout_id});

                        // trigger event to indicate that the slide out has loaded and is shown
                        $('#slideout-' + slideout_id).trigger('show.ud.slideout', $.extend({'slideout_id' : slideout_id, 'vars': data}, args));

                        // add visible class to slide out element
                        setTimeout(function () {
                            $('#slideout-' + slideout_id).removeClass('loading');
                        }, 10);

                        // update global variable that indicates a slide out has finished opening
                        window.slideout_opening = false;

                        // update global variable that indicates a slide out has opened
                        window.slideout_open = that.is_open();
                    },

                });

            }

        }

        // when slide out hide is requested
        if (action == 'hide') {

            // remove visible class on modal that will close it
            $('#slideout-' + slideout_id).closest('.slideout-wrapper').removeClass('visible').trigger('hide.ud.slideout', $.extend({'slideout_id' : slideout_id}, args));

            // remove body indicator class
            $('body').removeClass('slideout-open');

            // remove slide out element if specified in close args
            if (typeof args.remove_element_on_close !== 'undefined' && args.remove_element_on_close) {
                $('body').addClass('slideout-removing');

                setTimeout(function () {
                    $('#slideout-' + slideout_id).remove();
                    $('body').removeClass('slideout-removing');
                }, 800);
            }

            // update global variable that indicates a slide out has finished opening
            window.slideout_opening = false;

            // update global variable that indicates a slide out has closed
            window.slideout_open = that.is_open();

        }

        // when slide out refresh is requested
        if (action == 'refresh') {

            // add class and trigger opening event on body
            $(document.body).addClass('slideout-refreshing').trigger('slideout-refreshing', {'slideout_id' : slideout_id});

            // make ajax call to retrieve slider html
            window.slideout_xhr = $.ajax({
                url: window.get_ajax_url() + '?trigger=slide_out',
                data: {
                    action: 'ms_slide_out',
                    target: target,
                    args: args,
                    refresh: 1,
                },
                type: 'POST',
                dataType: 'json',
                beforeSend: function () {
                    if (window.slideout_xhr != null) {
                        window.slideout_xhr.abort();
                    }
                },
                success: function (data) {

                    // update content in slider
                    $('#slideout-' + slideout_id + ' .slideout-container').html(data.content);

                    // trigger event to indicate that the slide out content has refreshed
                    $('#slideout-' + slideout_id).trigger('refresh.ud.slideout', $.extend({'slideout_id' : slideout_id}, args));

                    // remove class and trigger opening event on body
                    $(document.body).trigger('slideout-refreshed', $.extend({'slideout_id' : slideout_id}, args)).removeClass('slideout-refreshing');

                },
            });
        }

    }
}


/**
 * Instantiate a new `udslideouts` object
 */
window.slideout_obj = new udslideouts();


/**
 * Global slide out controller functions
 */
window.slideout = (action, target, args = {}) => {
    window.slideout_obj.slideout(action, target, args);
};
