'use strict';

/**
 * Library for mandarin stone event tracking
 */
class msTracking {

    /**
     * On class construct
     */
    constructor() {

        // event listeners
        this.listeners();

    }

    /**
     * Event listeners
     */
    listeners() {

        // listen for forms
        this.forms();

        // listen for wish list actions
        this.wish_list();

        // listen for phone number clicks
        this.phone();

        // listen for menu clicks
        this.menu();

        // listen for proceed to checkout
        this.proceed_to_checkout_detection();

        // listen for checkout events
        this.checkout_event_detection();

        // instantiate a copy of self when out of scope
        let that = this;

        // listen for track event
        $(document.body).on('ud-event', function(e, event_name, vars) {
            console.log('track: ' + event_name);
            if (typeof that[event_name] === 'function') {
                that[event_name](e, vars);
            }
        });

    }

    /**
     * Listen for gravity form submissions
     */
    forms() {
        $(document.body).on('click', '#gform_submit_button_1', function() {
            $(document.body).trigger('ud-event', 'mailing_list_signup');
        });
        $(document.body).
            on('submit', '.newsletter-signup-form',
                function() {
                    $(document.body).trigger('ud-event', 'mailing_list_signup');
                });
        $(document.body).on('click', '#gform_submit_button_2', function() {
            $(document.body).
                trigger('ud-event', ($('body').hasClass('press-enquiries')
                    ? 'press_enquiries'
                    : 'general_enquiries'));
        });
        $(document.body).on('click', '#gform_submit_button_4', function() {
            $(document.body).trigger('ud-event', 'request_a_lookbook');
        });
        $(document.body).on('click', '#gform_submit_button_6', function() {
            $(document.body).trigger('ud-event', 'trade_enquiries');
        });
    }

    /**
     * Wish list tracking
     */
    wish_list() {
        $(document.body).
            on('click', '#mini-wishlist', function() {
                $(document.body).trigger('ud-event', 'click_wish_list_header');
            });
        $(document.body).on('click', '.product-add-to-wishlist', function() {
            $(document.body).trigger('ud-event', 'clicks_wish_list_page');
        });
    }


    /**
     * Gtag wrapper, check if its valid function to avoid erroring out.
     */
    gtag(event, action, vars) {
        if (typeof gtag === 'function') {
            gtag(event, action, vars);
        }
    }


    /**
     * GTA datalayer wrapper, check if data layer exists to avoid erroring out.
     */
    gta(event, vars = false)
    {
        if (typeof window.dataLayer !== 'undefined') {
            if (!vars) {
                vars = {};
            }
            if (event) {
                vars['event'] = event;
            }
            window.dataLayer.push(vars);
        }
    }


    /**
     * Listen for phone number clicks
     */
    phone() {
        $('a[href^="tel:"]').click(function() {
            this.gtag('event', 'Click to Call', {
                'event_category': 'Phone Call Tracking',
            });

        });
    }

    /**
     * Listen for menu clicks
     */
    menu() {
        let that = this;
        $(document).on('click', '.big-menu-section a', function() {
            let title = $(this).attr('data-menu-title');
            if(title) {
                that.gtag('event', 'Click', {
                    'event_category': 'Menu',
                    'event_label': title,
                });
            }

        });
    }

    /**
     * Listen for proceed to checkout
     */
    proceed_to_checkout_detection()
    {
        $(document.body).on('click', 'a[href="/checkout"]', function () {
            window.Cookies.set('continue_to_checkout', 1);
        });
    }


    /**
     * Listen for checkout events
     */
    checkout_event_detection()
    {
        // instantiate a copy of self when out of scope
        let that = this;

        // detect clicked delivery method
        $(document).on('click', '[data-target="checkoutPayment"]', function () {
            that.gta('checkout_selected_delivery_method', {'method_name' : $.trim($('[data-delivery_surcharge_id].selected').text().replace(/\s+/g, ' '))});
            that.gta('customer_information', {'email' : $('input[name="billing_email"]').val(), 'phone' : $('input[name="billing_phone"]').val()});
        });

        // track payment method function
        let trackPaymentMethod = (method_name) => {
            that.gta('checkout_selected_payment_method', {'method_name' : method_name});
            that.gta('customer_information', {'email' : $('input[name="billing_email"]').val(), 'phone' : $('input[name="billing_phone"]').val()});
        };

        // detect clicked payment method
        $(document).on('click', '#place_order, .ppc-button-wrapper', function () {
            trackPaymentMethod($('.payment_methods input:checked').val());
        });

        // detect paypal open
        const observer = new MutationObserver(mutations =>
            mutations.forEach(mutation =>
                mutation.addedNodes.forEach(node =>
                    node.nodeType === 1 && node.id  && typeof node.id === 'string' && node.id.startsWith('paypal-overlay') && trackPaymentMethod('PayPal')
                )
            )
        );
        observer.observe(document.body, { childList: true, subtree: true });
    }

    click_product_navigation(e, vars) {
        this.gtag('event', 'Click', {
            'event_category': 'Product Navigation',
            'event_label': vars['title'],
        });
    }

    click_product_filter(e, vars) {
        this.gtag('event', 'Click', {
            'event_category': 'Product Filter',
            'event_label': vars['title'],
        });
    }

    open_product_search() {
        this.gtag('event', 'Opened Search Bar', {
            'event_category': 'Product Search',
        });
    }

    click_search_result() {
        this.gtag('event', 'Clicked Search Result', {
            'event_category': 'Product Search',
        });
    }

    add_to_wishlist() {
        this.gtag('event', 'Submit', {
            'event_category': 'Add to wishlist',
        });
    }

    click_wish_list_header() {
        this.gtag('event', 'Wishlist View', {
            'event_category': 'Wishlist',
        });
    }

    clicks_wish_list_page() {
        this.gtag('event', 'Add to Wishlist', {
            'event_category': 'Wishlist',
        });
    }

    view_product_page(e, vars) {

        // send facebook an `ViewContent` event
        if (typeof fbq === 'function') {
            fbq('track', 'ViewContent', {
                content_ids: [vars.product_id],
                content_type: 'product',
                currency: 'GBP',
            });
        }

    }

    initiate_checkout() {

        // send facebook an `InitiateCheckout` event
        if (typeof fbq === 'function') {
            fbq('track', 'InitiateCheckout');
        }

    }

    add_to_cart(e, vars) {
        // get parent product id

        let parent_product_id = vars['product_id'];

        // get product id
        let product_id = typeof vars['variation_product_id'] === 'undefined'
            ? vars['product_id']
            : vars['variation_product_id'];

        // get quantity
        let quantity = typeof vars['quantity'] === 'undefined'
            ? 1
            : vars['quantity'];

        // get unit price
        let unit_price = typeof vars['unit_price'] === 'undefined'
            ? false
            : vars['unit_price'];

        // Send Pinterest Add To Cart Event
        if (product_id && quantity && typeof pintrk === 'function') {
            pintrk('track', 'AddToCart', {
                product_id: parent_product_id,
                order_quantity: quantity,
                currency: 'GBP',
            });
        }

        // send facebook add to cart event
        if (typeof fbq === 'function') {
            fbq('track', 'AddToCart', {
                content_ids: [parent_product_id],
                content_type: 'product',
                value: (unit_price * quantity),
                currency: 'GBP',
            });
        }
    }

    add_sample_to_cart(e, vars) {
        //this.gta('add_to_cart', {'ecommerce' : vars.vars.ecommerce});
    }

    product_image_zoom() {
        this.gtag('event', 'Zoom', {
            'event_category': 'Product Image',
        });

    }

    expand_category_description() {
        this.gtag('event', 'Click', {
            'event_category': 'Expand Category Description',
        });
    }

    show_delivery_date() {
        this.gtag('event', 'Calendar Opened', {
            'event_category': 'Delivery Date',
        });
    }

    mailing_list_signup() {
        this.gtag('event', 'Mailing List Signup', {
            'event_category': 'Forms',
        });
    }

    general_enquiries() {
        this.gtag('event', 'General Enquiries Submission', {
            'event_category': 'Forms',
        });
    }

    press_enquiries() {
        this.gtag('event', 'Press Enquiries Submission', {
            'event_category': 'Forms',
        });
    }

    trade_enquiries() {
        this.gtag('event', 'Trade Enquiries Submission', {
            'event_category': 'Forms',
        });
    }

    request_a_lookbook() {
        this.gtag('event', 'Request Brochure Submission', {
            'event_category': 'Forms',
        });
    }

    live_chat_opened() {
        this.gtag('event', 'Chat Opened', {
            'event_category': 'LiveChat',
        });
    }

    live_chat_started() {
        this.gtag('event', 'Chat Started', {
            'event_category': 'LiveChat',
        });
    }

    live_chat_closed() {
        this.gtag('event', 'Chat Closed', {
            'event_category': 'LiveChat',
        });
    }

    delivery_calculation_estimate() {
        this.gtag('event', 'Calculate Delivery', {
            'event_category': 'Basket',
        });
    }

    open_basket() {
        // clear ecommerce variable when basket opens
        // dataLayer.push({ ecommerce: null });
    }

    open_quickview() {

    }

    new_account_created() {
        this.gtag('event', 'Submit', {
            'event_category': 'Create account',
        });
    }

    submit_preorder() {
        this.gtag('event', 'Requested', {
            'event_category': 'Preorder',
        });
    }

    product_sort(e, vars) {
        this.gtag('event', vars['sort_by_value'], {
            'event_category': 'Product sort pop up',
        });
    }

    order_success(e, vars) {
        this.gtag('event', vars['payment_gateway'], {
            'event_category': 'Referral Gateway',
        });
    }

    product_next_page(e, vars) {
        this.gtag('event', 'Next Page', {
            'event_category': 'Pagination',
            'event_value': vars['page'],
        });
    }

    product_listing_filtered() {
        this.gtag('event', 'Filtered', {
            'event_category': 'Product listing',
            'event_label': window.location.pathname,
        });
    }

}

export default msTracking;
