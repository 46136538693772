import lozad from 'lozad/dist/lozad.js';
import Basket from '../util/Basket';
import wishList from '../util/wishList';
import msTracking from '../util/Tracking';
import msLiveChat from '../util/chat';
import msTrustpilotWidget from '../util/msTrustpilotWidget';
export default {
    init() {
        // JavaScript to be fired on all pages

        // IE11 compatibility
        if (typeof Object.assign != 'function') {
            Object.assign = function (target) {
                'use strict';
                if (target == null) {
                    throw new TypeError('Cannot convert undefined or null to object');
                }

                target = Object(target);
                for (var index = 1; index < arguments.length; index++) {
                    var source = arguments[index];
                    if (source != null) {
                        for (var key in source) {
                            if (Object.prototype.hasOwnProperty.call(source, key)) {
                                target[key] = source[key];
                            }
                        }
                    }
                }
                return target;
            };
        }

        // Initialize Lozad
        const observer = lozad(); // lazy loads elements with default selector as '.lozad'
        observer.observe();

        // Debounce the set height vars
        window.addEventListener('resize', window.debounce(() => {
            setVarHeights()
        }, 500));

        window.addEventListener('load', window.debounce(() => {
            setVarHeights()
        }, 500));

        // // Set height vars

        function setVarHeights() {
            let hh = document.querySelector('.main-header').offsetHeight;
            document.documentElement.style.setProperty('--headerHeight', `${hh}px`);

            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // Open Big Menu
        $(document.body).on('click', '.js-big-menu-trigger', function () {
            if($(this).hasClass('active')) { // allow click to close current item
                $('.big-menu-close').trigger('close-big-menu');
            } else {
                $(document.body).removeClass('search-box-focused');
                $('.js-big-menu-trigger').removeClass('active'); // Reset others
                $(this).addClass('active'); // Active Class on self
                $('body').addClass('big-menu-open');
                $('.mobile-menu-trigger').addClass('big-menu-close').removeClass('js-big-menu-trigger').addClass('active');
                $('body, html').animate({scrollTop: 0}, 300);
                $('.big-menu-wrap').addClass('visible'); // Shows big menu
                $('.big-menu-wrap .active').removeClass('active'); // Resets other sections to hidden, for moving between menus
                $('.navigation-level-2').removeClass('mobile-pointers-on'); // Resets other sections
                $($(this).data('target')).addClass('active'); // Shows data-target e.g. menu item.
            }
        });

        // Close Big Menu
        $(document.body).on('click close-big-menu', '.big-menu-close', function () {
            $('body').removeClass('big-menu-open'); // Remove body class
            $('.big-menu-wrap').removeClass('visible'); // Hide big menu
            $('.js-big-menu-trigger').removeClass('active'); // Reset Trigger
            $('.mobile-menu-trigger').removeClass('active').removeClass('big-menu-close').addClass('js-big-menu-trigger'); // Reset mobile menu trigger
            $('.searchanise-input').blur();

            setTimeout(function () {
                $('.big-menu-wrap .active').removeClass('active'); // Reset menus
                $('.sub-menu-holder').html(''); // Reset 2nd level
                $('.navigation-level-2').removeClass('active').removeClass('mobile-pointers-on'); // Reset 2nd level
                $(document.body).removeClass('search-box-focused');
            }, 400);
        });

        // Escape Key trigger above function
        $(document).keyup(function (e) {
            if (e.keyCode === 27 && $('body').hasClass('big-menu-open')) {
                $('.big-menu-close').trigger('close-big-menu');
            }
        });

        // Close on click outside
        $(document.body).on('click', '.navigation-section, .main-header-top, .main-header-top .col-md, .main-header-bottom', function(e) {
            if(e.target === e.currentTarget) {
                $('.big-menu-close').trigger('close-big-menu');
            }
        });

        // Mobile Menu Trigger
        $(document.body).on('click', '.mobile-menu-trigger:not(.active)', function () {
            $(this).addClass('big-menu-close').removeClass('js-big-menu-trigger');
        })

        // Info Nav
        $(document.body).on('click', '.navigation-level-1 li a', function () {
            let navLevel2 = $(this).closest('.navigation-section').find('.navigation-level-2');
            if($(this).parent('li').hasClass('menu-item-has-children')) {
                let subMenu = $(this).next('.sub-menu').clone(); // Get Sub Menu
                $('.navigation-level-1 li.menu-item-has-children.active').removeClass('active'); // Reset Active State
                $(this).parent('li').addClass('active');
                $(navLevel2).removeClass('mobile-pointers-on').addClass('active'); // Reset pointers, open 2nd Level
                $(navLevel2).find('.sub-menu-holder').fadeIn(50).html(subMenu); // Copy HTML into 2nd level
                setTimeout(function () {
                    $(navLevel2).addClass('mobile-pointers-on');
                }, 1000);
            } else { // Reset 2nd level if current hover has no sub-menu
                $('.navigation-level-1 li.menu-item-has-children').removeClass('active');
                $(navLevel2).find('.sub-menu-holder').fadeOut(50, function () {
                    $(this).html('');
                });
                $(navLevel2).removeClass('active').removeClass('mobile-pointers-on');
            }
        });

        // Prevent clicks on top level a tags
        $(document.body).on('click', '.navigation-level-1 li.menu-item-has-children a', function(e) {
            e.preventDefault();
        });

        // Showroom Menu Details
        $(document.body).on('click', '.showrooms-sub-menu a', function(e) {
            e.preventDefault();
            $('.showrooms-sub-menu li.active').removeClass('active');
            $(this).parent('li').addClass('active');
            let title = $(this).attr('data-title');
            let img = $(this).attr('data-img');
            let address = $(this).attr('data-address');
            let phone = $(this).attr('data-phone');
            let email = $(this).attr('data-email');
            let hours = $(this).attr('data-hours');
            let link = $(this).attr('href');

            $('.showroom-details-image').attr('src', img);
            $('.showroom-details-title').text(title);
            $('.showroom-details-address').html(address);
            $('.showroom-details-phone').html('T: ' + phone).attr('href', 'tel:' + phone);
            $('.showroom-details-email').html('E: ' + email).attr('href', 'mailto:' + email);
            $('.showroom-details-hours').html(hours);
            $('.showroom-details-link').attr('href', link);
        });


        // Closing the 2nd level
        $(document.body).on('click', '.navigation-level-2-close, .bot-navigation-back', function() {
            $('.navigation-level-1 li.menu-item-has-children').removeClass('active');
            $('.navigation-level-2 .sub-menu-holder').fadeOut(50, function () {
                $(this).html('');
            });
            $('.navigation-level-2').removeClass('active').removeClass('mobile-pointers-on');
        });

        // Menu event tracking
        $(document.body).on('click', '.big-menu-wrap a', function () {
            let title = $(this).attr('title');
            $(document.body).trigger('ud-event', ['click_product_navigation', {'title': title}]);
        });

        // run passed through function on any element that has `element_updated` triggered on it
        $(document).on('element_updated', '*', function (e, func) {
            if (func) {
                func();
            }
        });

        // Dropdown Toggle
        $(document.body).on('click', '.dropdown-toggle', function () {
            let target = $(this).attr('data-target');

            // If already open, close it
            if ($(this).attr('aria-expanded') == 'true') {
                $(this).attr('aria-expanded', false);
                $('#' + target).removeClass('show');
            } else {
                // Else close others, and open this one
                $('.dropdown-menu').removeClass('show');
                $('.dropdown-toggle').attr('aria-expanded', false);
                $(this).attr('aria-expanded', true);
                $('#' + target).addClass('show');
            }
        });

        // Close dropdown when clicking outside
        $(document.body).on('click', function (e) {
            // Check if the clicked element is not a dropdown-toggle or inside a dropdown
            if (!$(e.target).parents('.dropdown-menu').length && !$(e.target).hasClass('dropdown-toggle') && !$(e.target).parents('.product-filter-wrapper').length) {
                $('.dropdown-menu').removeClass('show');
                $('.dropdown-toggle').attr('aria-expanded', false);
            }
        });


        // Accordion Toggle
        $(document.body).on('click', '.accordion-toggle', function () {
            let target = $(this).data('target');
            let parent = $(this).data('parent');

            if ($(this).hasClass('expanded')) {
                $(this).removeClass('expanded');
                $('#' + target).slideUp(500).removeClass('open');
            } else {
                if (parent) {
                    $('#' + parent + ' .collapse').slideUp(500).removeClass('open');
                    $('#' + parent + ' .accordion-toggle').removeClass('expanded');
                }
                $(this).addClass('expanded');
                $('#' + target).slideDown(500).addClass('open').trigger('accordion.expanded');
            }
        });

        // Tab Toggle
        $('body').on('click', '.tab-toggle', function () {
            let target = $(this).data('target');

            if (!$(this).hasClass('active')) {
                $('.tab-toggle').removeClass('active');
                $('.tab-pane').hide().removeClass('open');

                $(this).addClass('active');
                $('#' + target).fadeIn(500).addClass('open');
            }
        });

        // Mobile jump select
        $('.jump-section').change(function () {
            let cTarget = $(this).val();
            $('html, body').animate({
                scrollTop: $('#' + cTarget).offset().top - 50,
            }, 500);
        });

        // Search trigger
        $(document.body).on('focus', '.searchanise-input', function () {
            if(!$('body').hasClass('search-box-focused')) {
                $('.js-search-trigger').click();
            }
        });

        // Load search on click on magnifying glass
        $(document.body).on('click', '.js-search-trigger', function () {
            let searchScript = $(this).attr('data-script');
            window.loadScript(searchScript, false);
        });

        // remove class `not-loaded-more` on block ajax listing if user has clicked the load more button
        $(document.body).on('click', '.ajax-load-more', function () {
            $(this).closest('.block-ajax-listing').find('.not-loaded-more').removeClass('not-loaded-more');
        });

        // remove `not-loaded-more` class if initially there are no more posts than the limit
        $(document.body).on('ud_ajax_request_complete', '.block-ajax-listing', function (e, obj) {
            if (obj.items_page != 2 || !$(e.currentTarget).closest('.block-ajax-listing').find('.not-loaded-more').length) {
                return;
            }
            if ($(e.currentTarget).find('article').length < obj['filter_args']['number_of_posts_per_page']) {
                $(e.currentTarget).closest('.block-ajax-listing').find('.not-loaded-more').removeClass('not-loaded-more');
            }
        });

        // Add class .active to #myModal when clicking on .project-grid-box
        $(document.body).on('click', '.project-grid-box', function (event) {

            // Find the closest .project-card within the clicked .project-grid-box
            let projectCard = $(this).find('.project-card');
            let cardsParent = $(this).parent();

            // Check if a .project-card was found
            if (projectCard.length) {
                // Get the data-id value from the closest .project-card
                let dataIdValue = projectCard.data('id');
                let hideArrows = projectCard.data('hide-arrows');

                // Perform your desired actions with the data-id value
                window.load_fragments('#myModal', {'cardsParent' : cardsParent}, {'dataIdValue': dataIdValue, 'hideArrows' : hideArrows});
                $('#myModal').attr('data-id', dataIdValue);
            }

            // Add a class to the clicked .project-grid-box
            $(this).addClass('active');

            // Stop the click event from propagating to the document body
            event.stopPropagation();

            // Add overflow: hidden to the body
            $('body').css('overflow', 'hidden');
        });


        // When the fragment is updated, add .active class and retrigger lazy load
        $(document.body).on('element_updated', '#myModal', function (e, args) {
            $(e.target).addClass('active');
            $(document.body).trigger('observeLozad');


            if (!args) {
                return;
            }

            var noOfelements = args['cardsParent'].find('[data-index]').length -1;
            var activeElement = args['cardsParent'].find('.active:not(#myModal)');
            var activeElementIndex = activeElement.find('[data-index]').attr('data-index');
            $('#back-arrow-js, #next-arrow-js').removeClass('disabled');

            if(activeElementIndex == 0) {

                $('#back-arrow-js').addClass('disabled');

            } else if(activeElementIndex == noOfelements) {

                $('#next-arrow-js').addClass('disabled');
            }
        });

        // Remove class .active when clicking outside .project-modal-content
        $(document.body).on('click', function (event) {
            if (!$(event.target).closest('.project-modal-content').length && $('#myModal').hasClass('active')) {
                $('.project-grid-box').removeClass('active');
                $('#myModal').removeClass('active');
                $('body').css('overflow', 'auto');
            }
        });

        // Close the modal when the 'Esc' key is pressed
        $(document).on('keydown', function (event) {
            if (event.key === 'Escape' && $('#myModal').hasClass('active')) {
                $('.project-grid-box').removeClass('active');
                $('#myModal').removeClass('active');
                $('body').css('overflow', 'auto');
            }
        });

        // Close the modal, close button
        $(document.body).on('click', '#close-modal-js', function () {
            // Prevent the default behavior of the click event
            $('.project-grid-box').removeClass('active');
            $('#myModal').removeClass('active');
            $('body').css('overflow', 'auto');
        });

        // Arrows in modal
        function handleArrowClick(direction) {
            var activeElement = $('.projects').find('.active:not(#myModal)');
            var targetElement = (direction === 'next') ? activeElement.next('div:first') : activeElement.prev('div:first');

            if (targetElement.length) {
                var targetDataId = targetElement.find('.project-card').data('id');
                window.load_fragments('#myModal', false, {'dataIdValue': targetDataId});

                targetElement.addClass('active');
                activeElement.removeClass('active');

                $('#back-arrow-js, #next-arrow-js').removeClass('disabled');

            } else {
                $('#' + direction + '-arrow-js').addClass('disabled');
            }
        }

        $(document.body).on('click', '#next-arrow-js', function () {
            handleArrowClick('next');
        });

        $(document.body).on('click', '#back-arrow-js', function () {
            handleArrowClick('back');
        });

        // listen for global fragment refresh event
        $(document.body).on('refresh_global_fragments', function () {
            window.load_fragments();
        });

        // initialize the fragments we only want to load when hovered into
        window.initialize_fragments_on_hover();

        // JS read more
        $(document.body).on('click', '.js-read-more', function () {
            $(this).prev('.js-read-more-wrapper').slideToggle(300);
            let text = $(this).text();
            $(this).text(text == 'Show Less' ? 'Show More' : 'Show Less');
        });

        // load basket library
        new Basket();

        // load wish-list library
        new wishList();

        // Lozad Observe Trigger
        $(document.body).on('observeLozad', function () {
            let observer = lozad();
            observer.observe();
        });

        // load mandarin tracking library
        new msTracking();

        // Load LiveChat integration
        new msLiveChat();

        // create a js instance of the trustpilot widget whenever one occurs
        $('.trustpilot-widget').each(function () {
            new msTrustpilotWidget(this);
        });
    },
}

