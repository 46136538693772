'use strict';

/**
 * modal library
 */
class udmodals {

    /**
     * Initialisation function
     */
    constructor()
    {

        // listen for other events
        this.event_listeners();

    }


    /**
     * Listen for other events
     */
    event_listeners()
    {

        // instantiate a copy of self when out of scope
        let that = this;

        // open/close modal listeners
        $(document).on('click', '[data-open-modal]', function (e) {
            that.data_open_modal_handler(e);
        });

        // close modal listeners
        $(document).on('click', '[data-close-modal]', function (e) {
            that.close_modal(e);
        });

        $(document).on('click', '.modal-wrapper:not(#modal-ie11)', function(e) {
            if (e.target === e.currentTarget) {
                $('[data-close-modal]').click();
            }
        });

        document.addEventListener('keydown', function (event) {
            if (event.key == 'Escape') {
                $('[data-close-modal]').click();
            }
        });

    }


    /**
     * Process `data_open_modal` click handler
     */
    data_open_modal_handler(e)
    {

        // prevent event bubbling
        e.stopPropagation();

        // prevent default action
        e.preventDefault();

        // button element
        let button_elem = $(e.currentTarget);

        // obtain modal target
        let target = button_elem.attr('data-open-modal');

        // get any modal args
        let args = this.get_element_args(button_elem, 'open');

        // perform modal show
        window.modal('show', target, args);

    }

    close_modal(e) {
        // determine modal target
        let modal_target = $(e.currentTarget).attr('data-close-modal') ? $(e.currentTarget).attr('data-close-modal')[0] : $(e.currentTarget).closest('.modal-wrapper')[0];

        // hide modal
        this.modal('hide', modal_target, this.get_element_args($(e.currentTarget), 'close'));
    }


    /**
     * Gets any additional arguments attached to the element
     */
    get_element_args(elem, type = '')
    {
        let attr_name = 'data' + (type ? '-' + type : '') + '-modal-args';
        return (elem[0].hasAttribute(attr_name) ? JSON.parse(elem.attr(attr_name)) : {});
    }


    /**
     * Detect if a slider is open
     */
    is_open()
    {
        return $('.modal-wrapper.visible').length
    }


    /**
     * modal show/hide function
     */
    modal(action, target, args = {})
    {

        // exit if target is null
        if (!target) {
            return;
        }

        // instantiate a copy of self when out of scope
        let that = this;

        // modal id
        let modal_id = $(target).attr('id').replace('#', '').replace('modal-', '');

        // when modal show is requested
        if (action == 'show') {
            // if modal element already exists then open it
            if ($('#modal-' + modal_id).length) {
                // add visible class to modal element
                $('#modal-' + modal_id).addClass('visible');

                // add `modal-open` class to body and trigger event
                $('body').trigger('modal-open', {'modal_id' : modal_id}).addClass('modal-open');

                // trigger event to indicate that the modal has loaded and is shown
                $('#modal-' + modal_id).trigger('show.ud.modal', $.extend({'modal_id' : modal_id}, args));

                // update global variable that indicates a modal has opened
                window.modal_open = that.is_open();
            }
        }

        // when modal hide is requested
        if (action == 'hide') {
            // remove visible class on modal that will close it
            $('#modal-' + modal_id).closest('.modal-wrapper').removeClass('visible').trigger('hide.ud.modal', $.extend({'modal_id' : modal_id}, args));

            // remove body indicator class
            if (!that.is_open()) {
                $('body').removeClass('modal-open');
            }

            // remove modal element if specified in close args
            if (typeof args.remove_element_on_close !== 'undefined' && args.remove_element_on_close) {
                setTimeout(function () {
                    $('#modal-' + modal_id).remove();
                }, 50);
            }

            // update global variable that indicates a modal has finished opening
            window.modal_opening = false;

            // update global variable that indicates a modal has closed
            window.modal_open = that.is_open();
        }

    }
}


/**
 * Instantiate a new `udmodals` object
 */
window.modal_obj = new udmodals();


/**
 * Global modal controller functions
 */
window.modal = (action, target, args = {}) => {
    window.modal_obj.modal(action, target, args);
};
